@charset "UTF-8";
@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

/* noto-sans-kr-regular - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 400; src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-500 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 500; src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-700 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 700; src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-regular - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 400; src: local("Roboto"), local("Roboto-Regular"), url("/css/fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-700 - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 700; src: local("Roboto Bold"), local("Roboto-Bold"), url("/css/fonts/roboto-v20-latin-700.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

@font-face { font-family: "11StreetGothic"; font-style: normal; font-weight: 400; src: url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff"); }

@font-face { font-family: "11StreetGothic"; src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff"); font-style: normal; font-weight: 700; }

@font-face { font-family: "11StreetGothicBold"; font-weight: bold; src: url("/css/fonts/11STREET_Gothic_bold.eot"); src: url("/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("/css/fonts/11STREET_Gothic_bold.ttf") format("truetype"); }

/* new lato-regular - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 400; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-700 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 700; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-900 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 900; src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button { margin: 0; padding: 0; }

html { overflow-y: auto; width: 100%; height: 100%; background: #eee; }

body { width: 100%; height: 100%; }

dl, ul, ol, menu, li { list-style: none; }

img, fieldset, iframe { border: 0 none; }

img { vertical-align: top; }

input, select, textarea, button { vertical-align: middle; }

input::-ms-clear { display: none; }

button { border: 0 none; background-color: transparent; cursor: pointer; border-radius: 0; box-sizing: border-box; }

table { border-collapse: collapse; border-spacing: 0; empty-cells: show; }

caption, legend { position: absolute; top: -9999px; left: -9999px; font-size: 0px; line-height: 0; }

a { color: #666; text-decoration: none; }

a:hover { text-decoration: underline; }

a:active { background-color: transparent; }

body, h1, h2, h3, h4, th, td, input, select, textarea, button { font-size: 14px; line-height: 1.5; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; letter-spacing: -1px; }

hr { display: none; }

address, caption, cite, code, dfn, em, var { font-style: normal; font-weight: normal; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ""; content: none; }

q:before, q:after { content: ""; content: none; }

.skip { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.sr-only { position: absolute; top: 0; width: 1px; height: 1px; clip: rect(0 0 0 0); margin: -1px; font-size: initial; line-height: 0; color: transparent; overflow: hidden; }

.vc { display: block; position: relative; top: 50%; -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); }

.bold { font-weight: bold; }

.number { font-family: "Roboto", Tahoma, sans-serif; }

.color-brand, .color_brand { color: #ff0038 !important; }

.color-secondary, .color_secondary { color: #0b83e6 !important; }

.scroll-lock, .scroll_lock { width: 100%; height: 100%; overflow: hidden; }

.scroll-lock body, .scroll_lock body { width: 100%; height: 100%; overflow: hidden; }

.footer-box { padding-bottom: 34px; background: #eee; }

.footer-box .footer-box__link { display: flex; background: #ddd; height: 54px; font-size: 0; }

.footer-box .footer-box__link li { flex: 1 1 auto; position: relative; z-index: 1; display: inline-block; text-align: center; }

.footer-box .footer-box__link li a { font-size: 12px; color: #333; line-height: 54px; letter-spacing: -0.9px; }

.footer-box .footer-box__link li:before { position: absolute; z-index: 1; top: 50%; left: 0; margin-top: -4px; display: block; width: 1px; height: 8px; opacity: 0.65; background-color: #ccc; content: ''; }

.footer-box .footer-box__link li:first-child:before { display: none; }

.footer-box .footer-box__link li:nth-child(4) a { font-weight: bold; }

.footer-box .footer-box__info { display: table; width: 100%; padding: 30px 0 27px; border-bottom: 1px solid #eee; }

.footer-box .footer-box__info .logo { display: table-cell; text-align: center; width: 12%; vertical-align: middle; }

.footer-box .footer-box__info .logo .logo-11st { display: inline-block; background-image: url("/img/selleroffice/sprites/sp_footer_2x.png"); background-position: 0px 0px; width: 78px; height: 32px; background-size: 78px 32px; overflow: hidden; line-height: 200px; }

.footer-box .footer-box__info .info { display: table-cell; font-size: 12px; color: #999; line-height: 19px; vertical-align: top; }

.footer-box .footer-box__info .info dt { color: #666; font-weight: bold; }

.footer-box .footer-box__info .info .tel { padding: 10px 0; font-size: 20px; color: #666; font-family: "Roboto", Tahoma, sans-serif; }

.footer-box .footer-box__info .info a { color: #999; }

.footer-box .footer-box__info .info .col { margin-left: 10px; font-size: 12px; color: #999; line-height: 19px; letter-spacing: -0.9px; vertical-align: middle; }

.footer-box .footer-box__info .info:nth-child(2) { width: 35%; }

.footer-box .footer-box__info .info:nth-child(3) { width: 27%; }

.footer-box .footer-box__info .info:nth-child(4) { width: 26%; }

.footer-box .footer-box_service { padding: 21px 0 0 12%; font-size: 0; border-top: 1px solid #ddd; }

.footer-box .footer-box_service .service_link { display: inline-block; min-width: 140px; height: 32px; font-size: 12px; line-height: 30px; border: 1px solid #ddd; background-color: #fff; text-align: center; color: #666; margin-left: 6px; }

.footer-box .footer-box_service .service_link:first-child { margin-left: 0; }

.footer-box .footer-box_copy { padding: 21px 0 0 12%; font-size: 11px; line-height: 22px; }

.footer-box .footer-box_copy .txt { font-weight: bold; }

@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

@font-face { font-family: "11StreetGothic"; src: url("/css/fonts/11Street-gothic_light_optimizing.woff") format("woff2"), url("/css/fonts/11Street-gothic_light_optimizing.woff") format("woff"); font-style: normal; }

@font-face { font-family: "11StreetGothic"; src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff"); font-weight: bold; }

/* noto-sans-kr-regular - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 400; src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-500 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 500; src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-700 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 700; src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* lato-regular - latin */
@font-face { font-family: "Lato"; font-style: normal; font-weight: 400; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v16-latin-regular.woff2") format("woff2"), url("/css/fonts/lato-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* lato-900 - latin */
@font-face { font-family: "Lato"; font-style: normal; font-weight: 900; src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v16-latin-900.woff2") format("woff2"), url("/css/fonts/lato-v16-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

@keyframes billboard-motion { from { opacity: 0; }
  to { opacity: 1; } }

.intro_wrap { position: relative; width: 100%; min-width: 1280px; margin: 0 auto; font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.intro_header { position: absolute; z-index: 2; top: 40px; width: 100%; min-width: 1280px; padding: 0 160px; zoom: 1; }

.intro_header:after { display: block; content: ""; clear: both; }

.intro_header .logo_11st { float: left; color: #fff; font-size: 26px; font-weight: bold; letter-spacing: -0.5px; line-height: 24px; }

.intro_header .logo_11st .logo { display: inline-block; margin-right: 8px; background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -532px -198px; width: 64px; height: 28px; background-size: 632px 344px; overflow: hidden; line-height: 200px; vertical-align: middle; }

.intro_header .navi { float: right; font-size: 0; }

.intro_header .navi li { display: inline-block; }

.intro_header .navi li:not(:first-child):before { display: inline-block; width: 1px; height: 16px; margin: 0 12px; vertical-align: middle; background-color: rgba(211, 211, 211, 0.4); content: ""; }

.intro_header .navi li a { color: #fff; font-size: 15px; letter-spacing: -0.42px; vertical-align: middle; }

.intro_content { padding-bottom: 90px; background-color: #fff; }

.intro_content .intro_top { position: relative; z-index: 1; width: 100%; height: 720px; background: url("//cdn.011st.com/ui_img/ui_develop/so/intro/top_bg.png") 50% 50% no-repeat; background-size: cover; }

.intro_content .intro_top .text { position: absolute; z-index: 1; top: 257px; left: 50%; background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: 0px 0px; width: 632px; height: 118px; background-size: 632px 344px; transform: translateX(-50%); overflow: hidden; line-height: 400px; }

.intro_content .intro_top .sub_text { position: absolute; z-index: 1; top: 395px; left: 50%; transform: translateX(-50%); font-size: 20px; letter-spacing: -1px; color: #fff; }

.intro_content .intro_top .sub_text + .btn_login { top: 462px; }

.intro_content .intro_top .btn_login { position: absolute; z-index: 1; top: 416px; left: 50%; transform: translateX(-50%); display: block; width: 216px; height: 60px; background-color: #f43142; border: 1px solid #b31422; color: #fff; font-size: 17px; font-weight: bold; letter-spacing: -1px; line-height: 60px; text-align: center; }

.intro_content .intro_top .btn_login:after { display: inline-block; width: 9px; height: 9px; border: 1px solid #fff; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); content: ""; }

.intro_content .intro-notice { padding: 48px 0; background-color: #222; }

.intro_content .intro-notice .inner { position: relative; z-index: 1; width: 960px; min-width: 960px; margin: 0 auto; padding-left: 166px; }

.intro_content .intro-notice .intro-notice__title { position: absolute; z-index: 1; top: 0; left: 0px; width: 166px; color: #fff; font-size: 22px; font-weight: normal; line-height: 28px; letter-spacing: -0.5px; }

.intro_content .intro-notice .intro-notice__list { display: inline-block; width: 100%; vertical-align: middle; }

.intro_content .intro-notice .intro-notice__list li { width: 100%; zoom: 1; }

.intro_content .intro-notice .intro-notice__list li:after { display: block; content: ""; clear: both; }

.intro_content .intro-notice .intro-notice__list li:not(:first-child) { margin-top: 13px; }

.intro_content .intro-notice .intro-notice__list li.today .text a { font-weight: bold; color: #fff; }

.intro_content .intro-notice .intro-notice__list li.today .date { color: #fff; }

.intro_content .intro-notice .intro-notice__list .subject { float: left; width: 90%; }

.intro_content .intro-notice .intro-notice__list .adv-noti { width: 60px; height: 24px; display: inline-block; border-radius: 12px; text-align: center; line-height: 22px; background: #222; color: #999; font-size: 11px; vertical-align: middle; border: 1px solid #999; }

.intro_content .intro-notice .intro-notice__list .new { margin-left: 4px; font-family: "Tahoma"; font-size: 12px; font-weight: bold; color: #f43142; letter-spacing: -1px; }

.intro_content .intro-notice .intro-notice__list .text { display: inline-block; max-width: 75%; height: 24px; margin-left: 12px; font-size: 16px; color: #666; overflow: hidden; vertical-align: middle; line-height: 24px; letter-spacing: -0.5px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.intro_content .intro-notice .intro-notice__list .text a { color: #999; }

.intro_content .intro-notice .intro-notice__list .date { float: right; width: 10%; text-align: right; font-size: 15px; color: #999; line-height: 24px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; vertical-align: middle; }

.intro_service { width: 960px; margin: 120px auto 90px; }

.intro_service .sup_text { color: #f43142; font-size: 16px; font-weight: normal; letter-spacing: 0; }

.intro_service .title { margin-top: 36px; font-size: 40px; font-weight: normal; line-height: 47px; letter-spacing: -1px; color: #111; }

.intro_service .title strong { color: #f43142; }

.intro_service .title sub { display: block; margin-top: 20px; color: #666; font-size: 16px; font-weight: normal; line-height: 22px; letter-spacign: -0.5px; }

.intro-box { margin-top: 90px; font-size: 0; }

.intro-box .intro-box__title { color: #111; font-size: 20px; line-height: 26px; letter-spacing: 0; }

.intro-box .intro-box__list { width: 100%; margin-top: 20px; border-top: 1px solid #333; }

.intro-box .intro-box__list li { position: relative; z-index: 1; border-bottom: 1px solid #ddd; }

.intro-box .intro-box__list li strong { font-size: 16px; color: #111; letter-spacing: -0.5px; }

.intro-box .intro-box__list li:before { position: absolute; z-index: 1; top: 0; left: 0; display: block; content: ""; }

.intro-box.intro_start li { width: 100%; padding-left: 70px; height: 70px; line-height: 70px; color: #666; font-size: 16px; }

.intro-box.intro_start li strong { display: inline-block; margin-right: 26px; }

.intro-box.intro_start li a { position: absolute; z-index: 1; right: 14px; color: #333; font-size: 14px; font-weight: bold; letter-spacing: -0.5px; }

.intro-box.intro_start li a:after { display: inline-block; width: 8px; height: 8px; margin: -2px 0 0 8px; border: 1px solid #999; border-width: 1px 1px 0 0; -webkit-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); transform-origin: 50% 50%; vertical-align: middle; content: ""; }

.intro-box.intro_start li:nth-child(1):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -491px -236px; width: 70px; height: 70px; background-size: 632px 344px; }

.intro-box.intro_start li:nth-child(2):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -532px -123px; width: 70px; height: 70px; background-size: 632px 344px; }

.intro-box.intro_start li:nth-child(3):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -416px -236px; width: 70px; height: 70px; background-size: 632px 344px; }

.intro-box.intro_start li:nth-child(4):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -341px -236px; width: 70px; height: 70px; background-size: 632px 344px; }

.intro-box.intro_start li:nth-child(5):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -266px -236px; width: 70px; height: 70px; background-size: 632px 344px; }

.intro-box.intro_up li { display: inline-block; width: 50%; padding-left: 120px; height: 108px; color: #666; font-size: 15px; }

.intro-box.intro_up li strong { display: block; font-size: 16px; margin-bottom: 9px; }

.intro-box.intro_up li a { position: absolute; z-index: 1; right: 14px; color: #333; font-size: 14px; font-weight: bold; letter-spacing: -0.5px; }

.intro-box.intro_up li a:after { display: inline-block; width: 8px; height: 8px; margin: -2px 0 0 8px; border: 1px solid #999; border-width: 1px 1px 0 0; -webkit-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); transform-origin: 50% 50%; vertical-align: middle; content: ""; }

.intro-box.intro_up li:nth-child(2n) { border-left: 1px solid #ddd; }

.intro-box.intro_up li:nth-child(1):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: 0px -236px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro-box.intro_up li:nth-child(2):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: 0px -123px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro-box.intro_up li:nth-child(3):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -133px -236px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro-box.intro_up li:nth-child(4):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -399px -123px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro-box.intro_up li:nth-child(5):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -266px -123px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro-box.intro_up li:nth-child(6):before { background-image: url("/img/selleroffice/sprites/sp_intro_2x.png"); background-position: -133px -123px; width: 128px; height: 108px; background-size: 632px 344px; }

.intro_banner { position: relative; z-index: 1; width: 960px; height: 120px; margin: 0 auto; }

.intro_banner .navi { position: absolute; z-index: 2; top: 10px; right: 10px; }

.intro_banner .navi .num { float: left; margin-right: 8px; font-family: "Roboto", Tahoma, sans-serif; font-size: 14px; line-height: 22px; color: #999; }

.intro_banner .navi .num strong { color: #333; font-weight: bold; }

.intro_banner .navi .num .skip { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.intro_banner .navi .btn_arrow { float: left; width: 22px; height: 22px; font-size: 0; line-height: 0; color: transparent; border: 1px solid #ddd; background: #fff; }

.intro_banner .navi .btn_arrow:before { display: inline-block; width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ""; vertical-align: middle; }

.intro_banner .navi .prev:before { transform: rotate(-135deg); margin-left: 1px; }

.intro_banner .navi .next { border-left: none; }

.intro_banner .list { position: relative; z-index: 1; }

.intro_banner .list li { display: none; position: absolute; z-index: 1; top: 0; left: 0; width: 100%; height: 120px; overflow: hidden; border-radius: 2px; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.intro_banner .list .active { display: block; z-index: 2; }

.intro_banner .list a { display: block; text-align: center; }

.intro_banner .list a img { width: 595px; height: 120px; }

.s_seller_intro { font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif, "11StreetGothic"; min-width: 1280px; }

.s_seller_intro body, .s_seller_intro h1, .s_seller_intro h2, .s_seller_intro h3, .s_seller_intro h4, .s_seller_intro th, .s_seller_intro td, .s_seller_intro input, .s_seller_intro select, .s_seller_intro textarea, .s_seller_intro button { letter-spacing: -0.2px; }

.s_seller_intro *, .s_seller_intro *:before, .s_seller_intro *:after { -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }

.s_seller_intro .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; font-size: 0; }

.s_seller_intro .l_seller_intro_header { z-index: 2; position: relative; width: 100%; max-width: 1280px; min-width: 1280px; margin: 34px auto; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_logo { z-index: 10; position: relative; width: 250px; margin-top: 8px; font-size: 30px; font-weight: bold; font-family: "11StreetGothic"; color: #fff; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_logo .logo:before { content: ""; display: inline-block; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -37px; width: 65px; height: 28px; background-size: 354px 292px; vertical-align: middle; margin-top: -4px; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb { z-index: 1; position: absolute; top: 14px; left: 0; width: 1280px; text-align: center; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li { position: relative; display: inline-block; padding: 0 12px 0 8px; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li:before { content: ""; position: absolute; top: 5px; right: 0; width: 1px; height: 17px; opacity: 0.4; background: #ddd; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li:first-child { padding-left: 0; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li:last-child { padding-right: 0; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li:last-child:before { display: none; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_gnb li a { font-size: 17px; color: #fff; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel { position: absolute; right: 0; top: 11px; zoom: 1; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel:after { display: block; content: ""; clear: both; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .login_info { float: left; margin: 4px 17px 0 0; font-size: 16px; color: #fff; vertical-align: middle; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .login_info:before { content: ""; display: inline-block; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -317px -164px; width: 24px; height: 24px; background-size: 354px 292px; vertical-align: middle; margin: -4px 2px 0 0; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .tel { float: left; font-size: 20px; color: #fff; vertical-align: middle; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .tel:before { content: ""; display: inline-block; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -205px; width: 24px; height: 24px; background-size: 354px 292px; vertical-align: middle; margin: -4px 2px 0 0; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .tel .num { display: inline-block; vertical-align: middle; font-weight: normal; margin: -4px 0 0 7px; font-size: 22px; font-family: "roboto"; }

.s_seller_intro .l_seller_intro_header .c_seller_intro_info_tel .tel .num a { color: #fff; }

.s_seller_intro .c_seller_intro_button_01 { display: inline-block; min-width: 178px; height: 58px; font-size: 18px; font-weight: bold; line-height: 58px; color: #fff; border: 1px solid #000; background: #222; }

.s_seller_intro .c_seller_intro_button_01:after { content: ""; display: inline-block; vertical-align: middle; margin: -3px 0 0 5px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -334px -205px; width: 16px; height: 16px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_button_01:hover { border: 1px solid #000; background: #111; }

.s_seller_intro .c_seller_intro_button_01.button_join { width: 220px; }

.s_seller_intro .c_seller_intro_button_02 { display: inline-block; min-width: 180px; height: 58px; font-size: 18px; line-height: 58px; font-weight: bold; color: #fff; border: 1px solid #fff; }

.s_seller_intro .c_seller_intro_button_02:after { content: ""; display: inline-block; vertical-align: middle; margin: -3px 0 0 5px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -334px -205px; width: 16px; height: 16px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_button_02.down:after { margin: -4px 0 0 5px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px -276px; width: 16px; height: 16px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_button_02:hover { border: 1px solid #fff; background: rgba(0, 0, 0, 0.1); }

.s_seller_intro .c_seller_intro_wing_top { z-index: 100; position: fixed; overflow: hidden; bottom: 50px; right: 50px; width: 60px; height: 60px; margin: 37px 0 15px 49px; line-height: 200px; border-radius: 30px; box-shadow: 0 12px 24px -8px rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: #fff; }

.s_seller_intro .c_seller_intro_wing_top:after { content: ""; display: block; position: absolute; top: 50%; left: 50%; margin: -12px 0 0 -12px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -305px -205px; width: 24px; height: 24px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_title { font-size: 42px; font-weight: normal; font-family: "11StreetGothic", "Apple SD Gothic Neo", "Noto Sans KR"; color: #1e1e1e; line-height: 1.31; text-align: center; }

.s_seller_intro .l_seller_intro_content { z-index: 1; position: relative; margin-top: -113px; }

.s_seller_intro .c_seller_intro_visual { width: 100%; min-width: 1280px; height: 360px; margin: 0 auto; padding-top: 200px; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling { overflow: hidden; z-index: 1; position: absolute; top: 0; width: 100%; height: 560px; min-width: 1280px; background: #000; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling .swiper-wrapper { animation: billboard-motion 1s; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling ul { overflow: hidden; position: absolute; top: 0; left: 50%; width: 100%; min-width: 1600px; height: 560px; margin: 0 0 0 -50%; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling ul li { position: absolute; top: 0; left: 0; opacity: 0; -webkit-transition: opacity 1s ease; transition: opacity 1s ease; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling ul li img { width: 100%; height: auto; min-height: 560px; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_main_rolling ul li.active { opacity: 1; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_title_button { z-index: 2; position: relative; text-align: center; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_title_button .text { font-size: 50px; line-height: 1.43; letter-spacing: -0.5px; color: #fff; font-family: "11StreetGothic"; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_title_button .button_list { margin-top: 31px; }

.s_seller_intro .c_seller_intro_visual .c_seller_intro_title_button .button_list li { display: inline-block; padding: 0 3px 0 4px; }

.s_seller_intro .c_seller_intro_guide_01 { min-width: 1280px; margin: 0 auto; padding: 90px 0 0 0; height: 667px; background: #f7f7f9; }

.s_seller_intro .c_seller_intro_guide_01 .type_list { overflow: hidden; width: 960px; padding-left: 1px; margin: 47px auto 0 auto; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li { position: relative; float: left; width: 318px; height: 424px; padding: 45px 0 0 0; margin: 0 0 0 -1px; text-align: center; border: 1px solid #e9e9e9; background: #fff; box-sizing: border-box; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li:first-child:nth-last-child(2), .s_seller_intro .c_seller_intro_guide_01 .type_list li:first-child:nth-last-child(2) ~ li { width: 50%; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li .icon { display: block; width: 96px; height: 96px; margin: 0 auto; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li .icon img { width: 96px; height: 96px; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li .text { margin-top: 17px; font-size: 16px; font-weight: bold; color: #333; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li dl { margin-top: 9px; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li dl dt { font-size: 26px; font-weight: bold; line-height: 1.31; color: #333; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li dl dd { height: 67px; margin-top: 13px; font-size: 16px; color: #616161; line-height: 1.5; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li .button_detail { display: block; width: 148px; height: 41px; margin: 0 auto; font-size: 16px; text-align: center; line-height: 41px; color: #111; border: 1px solid #ccc; border-radius: 30px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_01 .type_list li .button_detail:after { content: ""; display: inline-block; vertical-align: middle; margin: -4px 0 0 4px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -329px -234px; width: 16px; height: 16px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 { min-width: 1280px; margin: 0 auto; padding: 90px 0 0 0; height: 514px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_02 .step_list { overflow: hidden; width: 959px; margin: 58px auto 0 auto; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li { float: left; position: relative; width: 163px; padding: 0 36px 0 0; text-align: center; letter-spacing: 0; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li:before { content: ""; display: block; position: absolute; top: 53px; right: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -164px; width: 36px; height: 36px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li:last-child { padding: 0; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li:last-child:before { display: none; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li dl:before { content: ""; display: block; margin: 0 auto; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -207px -138px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li dl dt { margin-top: 17px; font-weight: bold; font-size: 20px; color: #333; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li dl dt span { display: block; margin-bottom: 3px; font-size: 16px; font-family: "roboto"; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li dl dd { height: 74px; margin-top: 16px; font-size: 15px; color: #666; line-height: 1.37; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li dl dd.button_box { height: auto; margin-top: 0; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li .button_detail { display: block; width: 110px; height: 34px; margin: 0 auto; font-size: 14px; text-align: center; line-height: 34px; color: #333; border: 1px solid #ddd; border-radius: 30px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li .button_detail:after { content: ""; display: inline-block; vertical-align: middle; margin: -4px 0 0 4px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -21px -276px; width: 13px; height: 13px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li.step_01 dl:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -207px -138px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li.step_02 dl:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px -207px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li.step_03 dl:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -69px -207px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li.step_04 dl:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -138px -207px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_02 .step_list li.step_05 dl:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -207px -207px; width: 64px; height: 64px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_03 { min-width: 1280px; margin: 0 auto; padding: 90px 0 0 0; height: 667px; background: #f7f7f9; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box { position: relative; width: 960px; padding: 0 62px 0 62px; margin: 52px auto 0 auto; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list { overflow: hidden; width: 960px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul { zoom: 1; position: relative; width: 4000px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li { float: left; width: 310px; margin-right: 15px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li:last-child { margin-right: 0; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li .thumb_box { width: 310px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li .thumb_box img { width: 100%; height: auto; display: block; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li dl { width: 268px; height: 120px; padding: 23px 20px 20px 20px; border: 1px solid #efefef; border-top: none; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li dl dt { font-size: 18px; font-weight: bold; color: #111; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li dl dd { margin-top: 12px; font-size: 15px; line-height: 1.5; color: #666; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li a:hover { text-decoration: none; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .guide_list ul li a:hover dd { text-decoration: underline; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .navigator { position: absolute; top: 0; left: 50%; width: 1084px; margin: 0 0 0 -542px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .navigator button { width: 1000px; text-indent: -9999px; position: absolute; top: 157px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .navigator button.btn_prev { left: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -117px; width: 42px; height: 42px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_03 .guide_list_box .navigator button.btn_next { right: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -70px; width: 42px; height: 42px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_04 { overflow: hidden; position: relative; min-width: 1280px; margin: 0 auto; padding: 90px 0 0 0; height: 590px; }

.s_seller_intro .c_seller_intro_guide_04:before { content: ""; z-index: 1; position: absolute; top: 0; left: 50%; display: block; width: 100%; min-width: 1600px; height: 700px; margin: 0 0 0 -50%; background: url("/img/selleroffice/seller_intro/bg_tv.png") center top no-repeat; background-size: 100%; }

.s_seller_intro .c_seller_intro_guide_04 .c_seller_intro_title { z-index: 2; position: relative; color: #fff; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box { z-index: 2; position: relative; width: 960px; padding: 0 62px 0 62px; margin: 52px auto 0 auto; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list { overflow: hidden; width: 960px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul { zoom: 1; position: relative; width: 4000px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li { float: left; width: 310px; margin-right: 15px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li:last-child { margin-right: 0; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li .thumb_box { position: relative; width: 310px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li .thumb_box img { width: 100%; height: auto; display: block; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li .thumb_box:before { content: ""; position: absolute; bottom: 20px; right: 20px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -323px -70px; width: 30px; height: 30px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li dl { width: 268px; height: 120px; padding: 23px 20px 20px 20px; border: 1px solid #efefef; border-top: none; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li dl dt { font-size: 18px; font-weight: bold; color: #111; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li dl dd { margin-top: 12px; font-size: 15px; line-height: 1.5; color: #666; word-break: normal; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li a:hover { text-decoration: none; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .guide_list ul li a:hover dd { text-decoration: underline; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .navigator { position: absolute; top: 0; left: 50%; width: 1084px; margin: 0 0 0 -542px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .navigator button { width: 1000px; text-indent: -9999px; position: absolute; top: 157px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .navigator button.btn_prev { left: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -117px; width: 42px; height: 42px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_04 .guide_list_box .navigator button.btn_next { right: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -70px; width: 42px; height: 42px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_05 { min-width: 1280px; margin: 0 auto; padding: 91px 0 91px 0; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro { position: relative; overflow: hidden; width: 960px; height: 245px; margin: 0 auto; background: #fff; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .visual { overflow: hidden; width: 960px; height: 246px; margin: 0 auto; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .visual ul { overflow: hidden; width: 96000px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .visual ul li { display: block; float: left; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .visual ul li img { width: 960px; max-height: 245px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .navigator { position: absolute; top: 10px; right: 10px; bottom: auto; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .navigator button { float: left; position: relative; overflow: hidden; line-height: 300px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .navigator button.previous { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -234px; width: 22px; height: 22px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .navigator button.next { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -303px -234px; width: 21px; height: 22px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .indicate { position: absolute; top: 12px; right: 61px; font-size: 14px; letter-spacing: 1px; line-height: normal; box-sizing: border-box; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro .indicate .pagination .current { font-weight: bold; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro.resize { height: 325px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro.resize .visual { height: 325px; }

.s_seller_intro .c_seller_intro_guide_05 .c_billborad_seller_intro.resize .visual ul li img { max-height: 325px; }

.s_seller_intro .c_seller_intro_guide_06 { min-width: 1280px; margin: 0 auto; padding: 50px 0 0 0; height: 152px; background: #f7f7f9; }

.s_seller_intro .c_seller_intro_guide_06 ul { zoom: 1; width: 963px; margin: 0 auto; }

.s_seller_intro .c_seller_intro_guide_06 ul:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_guide_06 ul li { float: left; width: 319px; border-right: 1px solid #ebebeb; }

.s_seller_intro .c_seller_intro_guide_06 ul li:last-child { border: none; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl { text-align: center; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dt { font-size: 18px; color: #333; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd { margin-top: 6px; color: #666; font-size: 16px; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd strong { display: block; margin-bottom: 8px; font-size: 28px; color: #333; font-family: "11StreetGothic"; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd .button_email { display: block; margin: 8px auto 16px auto; text-indent: -9999px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -323px -117px; width: 30px; height: 20px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd .button_ad { display: block; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd .button_ad:before { content: ""; display: block; margin: 17px auto 19px auto; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -323px -117px; width: 30px; height: 20px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_guide_06 ul li dl dd .button_ad:hover { text-decoration: none; }

.s_seller_intro .c_seller_intro_guide_06.type_02 ul li { width: 239px; }

.s_seller_intro .c_seller_intro_guide_06.type_02 ul li dl dd .button_email { margin: 17px auto 19px auto; }

.s_seller_intro .c_seller_intro_guide_06.type_03 ul li { width: 226px; }

.s_seller_intro .c_seller_intro_guide_06.type_03 ul li:first-child { width: 280px; }

.s_seller_intro .c_seller_intro_guide_07 { overflow: hidden; position: relative; min-width: 1280px; margin: 0 auto; padding: 90px 0 0 0; height: 602px; }

.s_seller_intro .c_seller_intro_guide_07:before { content: ""; z-index: 1; position: absolute; top: 0; left: 50%; display: block; width: 100%; min-width: 1600px; height: 692px; margin: 0 0 0 -50%; background: url("/img/selleroffice/seller_intro/bg_ad.png") center top no-repeat; background-size: 100%; }

.s_seller_intro .c_seller_intro_guide_07 .c_seller_intro_title { z-index: 2; position: relative; color: #fff; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list { zoom: 1; z-index: 2; position: relative; width: 975px; margin: 52px auto 0 auto; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li { float: left; width: 310px; height: 351px; margin: 0 7px 0 8px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .thumb_box { width: 310px; height: 174px; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .thumb_box img { width: 310px; height: 174px; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .text_button { margin-top: 20px; text-align: center; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .text_button .text { font-size: 18px; font-weight: bold; color: #111; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .text_button .button_link { display: inline-block; border: 1px solid #ccc; width: 170px; height: 41px; margin-top: 20px; font-size: 16px; text-align: center; color: #111; line-height: 43px; border-radius: 22px; background: #fff; }

.s_seller_intro .c_seller_intro_guide_07 .guide_list li .text_button .button_link:after { content: ""; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -323px -142px; width: 16px; height: 16px; background-size: 354px 292px; display: inline-block; margin-top: -3px; vertical-align: middle; }

.s_seller_intro .l_seller_intro_footer { min-width: 1280px; background: #eee; }

.s_seller_intro .c_seller_intro_footer_menu { height: 54px; text-align: center; background: #ddd; }

.s_seller_intro .c_seller_intro_footer_menu li { display: inline-block; line-height: 54px; padding: 0 24px 0 23px; }

.s_seller_intro .c_seller_intro_footer_menu li a { font-size: 13px; color: #333; }

.s_seller_intro .c_seller_intro_footer_menu li:nth-child(4) { font-weight: bold; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box { position: relative; width: 1005px; margin: 0 auto; padding: 35px 0 31px 275px; border-bottom: 1px solid #ddd; zoom: 1; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box:before { content: ""; display: block; position: absolute; top: 67px; left: 160px; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px 0px; width: 78px; height: 32px; background-size: 354px 292px; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box div { float: left; padding-right: 40px; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box div:last-child { padding-right: 0; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box div dt { font-size: 12px; color: #666; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box div dd { margin-top: 4px; font-size: 12px; color: #999; }

.s_seller_intro .c_seller_intro_footer_info .infomation_box div dd.tel { margin: 5px 0 10px 0; font-size: 20px; color: #666; letter-spacing: -0.5px; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box { width: 1005px; margin: 0 auto; padding: 21px 0 31px 275px; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .link_box { zoom: 1; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .link_box:after { display: block; content: ""; clear: both; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .link_box li { float: left; margin-right: 6px; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .link_box li a { display: inline-block; min-width: 106px; height: 30px; padding: 0 16px 0 16px; font-size: 12px; text-align: center; color: #666; line-height: 32px; border: 1px solid #ddd; background: #fff; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .text { margin-top: 22px; font-size: 11px; font-weight: bold; color: #666; }

.s_seller_intro .c_seller_intro_footer_info .link_copyright_box .copyright { margin-top: 6px; font-size: 11px; color: #666; }

.c_modal { display: none; position: fixed; left: 0; top: 0; right: 0; bottom: 0; }

.c_modal.active { display: block; }

.c_modal.c_modal_dimmed { background: rgba(0, 0, 0, 0.2); }

.c_modal .modal_inner { position: relative; margin: 50vh auto; transform: translateY(-50%); background: #fff; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); }

.c_modal .modal_inner .modal_header { border-bottom: 1px solid #eee; }

.c_modal .modal_inner .modal_header .title { text-align: center; }

.c_modal .modal_inner .modal_content.scroll { overflow-y: auto; height: 100px; }

.c_modal .modal_inner .modal_footer { border-top: 1px solid #ddd; text-align: center; }

.c_modal .modal_inner .modal_footer button { display: inline-block; margin: 0 5px; }

.c_modal .modal_inner .modal_close button { position: absolute; top: 17px; right: 21px; width: 38px; height: 38px; overflow: hidden; line-height: 200px; }

.c_modal .modal_inner .modal_close button:before, .c_modal .modal_inner .modal_close button:after { position: absolute; top: 50%; left: 50%; width: 24px; height: 1px; background-color: #111; content: ""; }

.c_modal .modal_inner .modal_close button:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.c_modal .modal_inner .modal_close button:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.c_modal_seller_intro { z-index: 110; }

.c_modal_seller_intro .modal_inner { width: 960px; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); }

.c_modal_seller_intro .modal_inner .modal_header { height: 70px; border-bottom: 1px solid #ddd; }

.c_modal_seller_intro .modal_inner .modal_header .title { font-size: 18px; line-height: 70px; color: #111; }

.c_modal_seller_intro .modal_inner .modal_content { padding: 20px; }

.c_modal_seller_intro .modal_inner .modal_content.scroll { height: 560px; padding: 20px 39px 39px 39px; max-height: 560px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content { display: none; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content.active { display: block; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .title { font-size: 16px; font-weight: bold; color: #111; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 { margin-top: 25px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box { zoom: 1; height: 138px; margin-top: 9px; padding: 22px 0 0 0; text-align: center; border: 1px solid #eee; background: #fafafa; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box:after { display: block; content: ""; clear: both; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li { position: relative; display: inline-block; width: 133px; padding-right: 36px; font-size: 14px; font-weight: bold; text-align: center; color: #333; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li:last-child { padding-right: 0; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li:last-child:after { display: none; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li:after { content: ""; position: absolute; top: 30px; right: 0; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -276px -261px; width: 36px; height: 8px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li:before { content: ""; display: block; margin: 0 auto 3px auto; background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px 0px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_01:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px 0px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_02:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -69px 0px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_03:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px -69px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_04:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -69px -69px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_05:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -138px 0px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_06:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -138px -69px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_07:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: 0px -138px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_01 .process_box li.type_08:before { background-image: url("/img/selleroffice/sprites/sp_seller_intro_2x_2022919_160003.png"); background-position: -69px -138px; width: 64px; height: 64px; background-size: 354px 292px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_02 { padding-top: 41px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_02 ul { margin-top: 10px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_02 ul li { position: relative; padding-left: 13px; font-size: 15px; color: #111; line-height: 1.67; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_02 ul li:before { content: ""; position: absolute; top: 10px; left: 3px; display: block; width: 3px; height: 3px; background: #494949; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_03 { padding-top: 39px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_03 .text_list_box { margin-top: 12px; font-size: 14px; color: #666; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_03 .text_list_box li { margin-bottom: 2px; }

.c_modal_seller_intro .modal_inner .modal_content .c_tab_content .info_03 .text_list_box li a { color: #0b83e6; }

.c_modal_seller_intro .modal_inner .modal_footer { padding: 10px 0; text-align: center; }

.c_modal_seller_intro .modal_inner .modal_footer button { min-width: 178px; height: 48px; line-height: 48px; padding: 0 30px; font-weight: bold; font-size: 15px; border: 1px solid #999; background: #fff; }

.c_modal_seller_intro .modal_inner .modal_footer button.confirm { border: none; background: #ff0038; color: #fff; }

.c_table_seller_intro { margin-top: 11px; border-top: 1px solid #111; }

.c_table_seller_intro table { width: 100%; table-layout: fixed; text-align: center; border: 1px solid #ebebeb; border-top: none; background: #fff; }

.c_table_seller_intro table th { height: 46px; font-size: 15px; color: #111; border-right: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb; }

.c_table_seller_intro table th:last-child { border-right: none; }

.c_table_seller_intro table td { padding: 20px; font-size: 15px; color: #111; border-right: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb; text-align: left; }

.c_table_seller_intro table td:last-child { border-right: none; }

.c_table_seller_intro table td ul li { line-height: 1.67; }

.c_table_seller_intro table td.top { vertical-align: top; }

.c_tab_seller_intro ul { zoom: 1; }

.c_tab_seller_intro ul:after { display: block; content: ""; clear: both; }

.c_tab_seller_intro ul li { float: left; width: calc(100% / 3); }

.c_tab_seller_intro ul li:first-child > button::before { left: 0; }

.c_tab_seller_intro ul li > button { position: relative; display: block; width: 100%; font-size: 16px; font-weight: bold; line-height: 60px; color: #111; font-weight: bold; box-sizing: border-box; text-align: center; background-color: #fff; }

.c_tab_seller_intro ul li > button::before { position: absolute; left: -1px; top: 0; bottom: 0; right: 0; border: 1px solid #ddd; content: ""; }

.c_tab_seller_intro ul li > button[aria-selected="true"] { background-color: #ff0038; color: #fff; }

.c_tab_seller_intro ul li > button[aria-selected="true"]:before { right: -1px; border-color: #ff0038; }

.c_tab_seller_intro ul li > button[aria-selected="true"]:hover { color: #fff; }

.c_tab_seller_intro ul li > button:hover { color: #ff0038; }

.c_tab_seller_intro .tab_inner { overflow: hidden; }
