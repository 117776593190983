@charset "UTF-8";
@import "../selleroffice/mixins"; //믹스인모음

@import "../selleroffice/helpers/variables"; //변수모음
@import "../selleroffice/helpers/reset"; //스타일 초기화

@import "../selleroffice/sprites/sp_intro_2x.scss";
@import "../selleroffice/sprites/sp_seller_intro_2x.scss";

@import "../selleroffice/partial/common.scss";
@import "../selleroffice/partial/footer.scss";

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}

@font-face {
    font-family: "11StreetGothic";
    src: url("/css/fonts/11Street-gothic_light_optimizing.woff") format("woff2"), url("/css/fonts/11Street-gothic_light_optimizing.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "11StreetGothic";
    src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
    font-weight: bold;
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v16-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v16-latin-regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v16-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v16-latin-900.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@keyframes billboard-motion {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.intro_wrap {
    position: relative;
    width: 100%;
    min-width: 1280px;
    margin: 0 auto;
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}

.intro_header {
    position: absolute;
    z-index: 2;
    top: 40px;
    width: 100%;
    min-width: 1280px;
    padding: 0 160px;
    @include clearfix();

    .logo_11st {
        float: left;
        color: #fff;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 24px;

        .logo {
            display: inline-block;
            margin-right: 8px;
            @include sprite-retina($sp_intro_2x_logo);
            overflow: hidden;
            line-height: 200px;
            vertical-align: middle;
        }
    }

    .navi {
        float: right;
        font-size: 0;

        li {
            display: inline-block;

            &:not(:first-child):before {
                display: inline-block;
                width: 1px;
                height: 16px;
                margin: 0 12px;
                vertical-align: middle;
                background-color: rgba(211, 211, 211, 0.4);
                content: "";
            }

            a {
                color: #fff;
                font-size: 15px;
                letter-spacing: -0.42px;
                vertical-align: middle;
            }
        }
    }
}

.intro_content {
    padding-bottom: 90px;
    background-color: #fff;

    .intro_top {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 720px;
        background: url("//cdn.011st.com/ui_img/ui_develop/so/intro/top_bg.png") 50% 50% no-repeat;
        background-size: cover;

        .text {
            position: absolute;
            z-index: 1;
            top: 257px;
            left: 50%;
            @include sprite-retina($sp_intro_2x_text);
            transform: translateX(-50%);
            overflow: hidden;
            line-height: 400px;
        }

        .sub_text {
            position: absolute;
            z-index: 1;
            top: 395px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 20px;
            letter-spacing: -1px;
            color: #fff;

            & + .btn_login {
                top: 462px;
            }
        }

        .btn_login {
            position: absolute;
            z-index: 1;
            top: 416px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 216px;
            height: 60px;
            background-color: $color-brand;
            border: 1px solid #b31422;
            color: #fff;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: -1px;
            line-height: 60px;
            text-align: center;

            &:after {
                display: inline-block;
                @include ico-angle(right, 9px, #fff, 1px, static);
                content: "";
            }
        }
    }

    .intro-notice {
        padding: 48px 0;
        background-color: #222;

        .inner {
            position: relative;
            z-index: 1;
            width: 960px;
            min-width: 960px;
            margin: 0 auto;
            padding-left: 166px;
        }

        .intro-notice__title {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0px;
            width: 166px;
            color: #fff;
            font-size: 22px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: -0.5px;
        }

        .intro-notice__list {
            display: inline-block;
            width: 100%;
            vertical-align: middle;

            li {
                width: 100%;
                @include clearfix();

                &:not(:first-child) {
                    margin-top: 13px;
                }

                &.today {
                    .text a {
                        font-weight: bold;
                        color: #fff;
                    }

                    .date {
                        color: #fff;
                    }
                }
            }

            .subject {
                float: left;
                width: 90%;
            }

            .adv-noti {
                width: 60px;
                height: 24px;
                display: inline-block;
                border-radius: 12px;
                text-align: center;
                line-height: 22px;
                background: #222;
                color: #999;
                font-size: 11px;
                vertical-align: middle;
                border: 1px solid #999;
            }

            .new {
                margin-left: 4px;
                font-family: "Tahoma";
                font-size: 12px;
                font-weight: bold;
                color: $color-brand;
                letter-spacing: -1px;
            }

            .text {
                display: inline-block;
                max-width: 75%;
                height: 24px;
                margin-left: 12px;
                font-size: 16px;
                color: #666;
                overflow: hidden;
                vertical-align: middle;
                line-height: 24px;
                letter-spacing: -0.5px;
                @include text-ellipsis();

                a {
                    color: #999;
                }
            }

            .date {
                float: right;
                width: 10%;
                text-align: right;
                font-size: 15px;
                color: #999;
                line-height: 24px;
                font-family: $font-family-number;
                letter-spacing: 0;
                vertical-align: middle;
            }
        }
    }
}

.intro_service {
    width: 960px;
    margin: 120px auto 90px;

    .sup_text {
        color: $color-brand;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0;
    }

    .title {
        margin-top: 36px;
        font-size: 40px;
        font-weight: normal;
        line-height: 47px;
        letter-spacing: -1px;
        color: #111;

        strong {
            color: $color-brand;
        }

        sub {
            display: block;
            margin-top: 20px;
            color: #666;
            font-size: 16px;
            font-weight: normal;
            line-height: 22px;
            letter-spacign: -0.5px;
        }
    }
}

.intro-box {
    margin-top: 90px;
    font-size: 0;

    .intro-box__title {
        color: #111;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0;
    }

    .intro-box__list {
        width: 100%;
        margin-top: 20px;
        border-top: 1px solid #333;

        li {
            position: relative;
            z-index: 1;
            border-bottom: 1px solid #ddd;

            strong {
                font-size: 16px;
                color: #111;
                letter-spacing: -0.5px;
            }

            &:before {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                content: "";
            }
        }
    }

    &.intro_start {
        li {
            width: 100%;
            padding-left: 70px;
            height: 70px;
            line-height: 70px;
            color: #666;
            font-size: 16px;

            strong {
                display: inline-block;
                margin-right: 26px;
            }

            a {
                position: absolute;
                z-index: 1;
                right: 14px;
                color: #333;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.5px;

                &:after {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin: -2px 0 0 8px;
                    border: 1px solid #999;
                    border-width: 1px 1px 0 0;
                    -webkit-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    transform-origin: 50% 50%;
                    vertical-align: middle;
                    content: "";
                }
            }
        }

        li:nth-child(1):before {
            @include sprite-retina($sp_intro_2x_icon_start1);
        }

        li:nth-child(2):before {
            @include sprite-retina($sp_intro_2x_icon_start2);
        }

        li:nth-child(3):before {
            @include sprite-retina($sp_intro_2x_icon_start3);
        }

        li:nth-child(4):before {
            @include sprite-retina($sp_intro_2x_icon_start4);
        }

        li:nth-child(5):before {
            @include sprite-retina($sp_intro_2x_icon_start5);
        }
    }

    &.intro_up {
        li {
            display: inline-block;
            width: 50%;
            padding-left: 120px;
            height: 108px;
            color: #666;
            font-size: 15px;

            strong {
                display: block;
                font-size: 16px;
                margin-bottom: 9px;
            }

            a {
                position: absolute;
                z-index: 1;
                right: 14px;
                color: #333;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.5px;

                &:after {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin: -2px 0 0 8px;
                    border: 1px solid #999;
                    border-width: 1px 1px 0 0;
                    -webkit-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    transform-origin: 50% 50%;
                    vertical-align: middle;
                    content: "";
                }
            }
        }

        li:nth-child(2n) {
            border-left: 1px solid #ddd;
        }

        li:nth-child(1):before {
            @include sprite-retina($sp_intro_2x_icon_up1);
        }

        li:nth-child(2):before {
            @include sprite-retina($sp_intro_2x_icon_up2);
        }

        li:nth-child(3):before {
            @include sprite-retina($sp_intro_2x_icon_up3);
        }

        li:nth-child(4):before {
            @include sprite-retina($sp_intro_2x_icon_up4);
        }

        li:nth-child(5):before {
            @include sprite-retina($sp_intro_2x_icon_up5);
        }

        li:nth-child(6):before {
            @include sprite-retina($sp_intro_2x_icon_up6);
        }
    }
}

.intro_banner {
    position: relative;
    z-index: 1;
    width: 960px;
    height: 120px;
    margin: 0 auto;

    .navi {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;

        .num {
            float: left;
            margin-right: 8px;
            font-family: $font-family-number;
            font-size: 14px;
            line-height: 22px;
            color: #999;

            strong {
                color: #333;
                font-weight: bold;
            }

            .skip {
                position: absolute;
                overflow: hidden;
                clip: rect(0 0 0 0);
                margin: -1px;
                width: 1px;
                height: 1px;
            }
        }

        .btn_arrow {
            float: left;
            width: 22px;
            height: 22px;
            font-size: 0;
            line-height: 0;
            color: transparent;
            border: 1px solid #ddd;
            background: #fff;

            &:before {
                display: inline-block;
                width: 6px;
                height: 6px;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
                vertical-align: middle;
            }
        }

        .prev {
            &:before {
                transform: rotate(-135deg);
                margin-left: 1px;
            }
        }

        .next {
            border-left: none;
        }
    }

    .list {
        position: relative;
        z-index: 1;

        li {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 120px;
            overflow: hidden;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);
        }

        .active {
            display: block;
            z-index: 2;
        }

        a {
            display: block;
            text-align: center;

            img {
                width: 595px;
                height: 120px;
            }
        }
    }
}

.s_seller_intro {
    font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif, "11StreetGothic";

    body,
    h1,
    h2,
    h3,
    h4,
    th,
    td,
    input,
    select,
    textarea,
    button {
        letter-spacing: -0.2px;
    }

    *,
    *:before,
    *:after {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .sr-only {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        clip: auto;
        white-space: normal;
        font-size: 0;
    }

    min-width: 1280px;

    .l_seller_intro_header {
        z-index: 2;
        position: relative;
        width: 100%;
        max-width: 1280px;
        min-width: 1280px;
        margin: 34px auto;

        .c_seller_intro_logo {
            z-index: 10;
            position: relative;
            width: 250px;
            margin-top: 8px;
            font-size: 30px;
            font-weight: bold;
            font-family: "11StreetGothic";
            color: #fff;

            .logo {
                &:before {
                    content: "";
                    display: inline-block;
                    @include sprite-retina($sp_seller_intro_2x_logo);
                    vertical-align: middle;
                    margin-top: -4px;
                }
            }
        }

        .c_seller_intro_gnb {
            z-index: 1;
            position: absolute;
            top: 14px;
            left: 0;
            width: 1280px;
            text-align: center;

            li {
                position: relative;
                display: inline-block;
                padding: 0 12px 0 8px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    right: 0;
                    width: 1px;
                    height: 17px;
                    opacity: 0.4;
                    background: #ddd;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;

                    &:before {
                        display: none;
                    }
                }

                a {
                    font-size: 17px;
                    color: #fff;
                }
            }
        }

        .c_seller_intro_info_tel {
            position: absolute;
            right: 0;
            top: 11px;
            @include clearfix;

            .login_info {
                float: left;
                margin: 4px 17px 0 0;
                font-size: 16px;
                color: #fff;
                vertical-align: middle;

                &:before {
                    content: "";
                    display: inline-block;
                    @include sprite-retina($sp_seller_intro_2x_ico_login);
                    vertical-align: middle;
                    margin: -4px 2px 0 0;
                }
            }

            .tel {
                float: left;
                font-size: 20px;
                color: #fff;
                vertical-align: middle;

                &:before {
                    content: "";
                    display: inline-block;
                    @include sprite-retina($sp_seller_intro_2x_ico_tel);
                    vertical-align: middle;
                    margin: -4px 2px 0 0;
                }

                .num {
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: normal;
                    margin: -4px 0 0 7px;
                    font-size: 22px;
                    font-family: "roboto";

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .c_seller_intro_button_01 {
        display: inline-block;
        min-width: 178px;
        height: 58px;
        font-size: 18px;
        font-weight: bold;
        line-height: 58px;
        color: #fff;
        border: 1px solid #000;
        background: #222;

        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin: -3px 0 0 5px;
            @include sprite-retina($sp_seller_intro_2x_ico_arrow_01);
        }

        &:hover {
            border: 1px solid #000;
            background: #111;
        }

        &.button_join {
            width: 220px;
        }
    }

    .c_seller_intro_button_02 {
        display: inline-block;
        min-width: 180px;
        height: 58px;
        font-size: 18px;
        line-height: 58px;
        font-weight: bold;
        color: #fff;
        border: 1px solid #fff;

        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin: -3px 0 0 5px;
            @include sprite-retina($sp_seller_intro_2x_ico_arrow_01);
        }

        &.down {
            &:after {
                margin: -4px 0 0 5px;
                @include sprite-retina($sp_seller_intro_2x_ico_down);
            }
        }

        &:hover {
            border: 1px solid #fff;
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .c_seller_intro_wing_top {
        z-index: 100;
        position: fixed;
        overflow: hidden;
        bottom: 50px;
        right: 50px;
        width: 60px;
        height: 60px;
        margin: 37px 0 15px 49px;
        //padding: 18px;
        line-height: 200px;
        border-radius: 30px;
        box-shadow: 0 12px 24px -8px rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        background: #fff;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -12px 0 0 -12px;
            @include sprite-retina($sp_seller_intro_2x_ico_top);
        }
    }

    .c_seller_intro_title {
        font-size: 42px;
        font-weight: normal;
        font-family: "11StreetGothic", "Apple SD Gothic Neo", "Noto Sans KR";
        color: #1e1e1e;
        line-height: 1.31;
        text-align: center;
    }

    .l_seller_intro_content {
        z-index: 1;
        position: relative;
        margin-top: -113px;
    }

    .c_seller_intro_visual {
        width: 100%;
        min-width: 1280px;
        height: 360px;
        margin: 0 auto;
        padding-top: 200px;

        .c_seller_intro_main_rolling {
            overflow: hidden;
            z-index: 1;
            position: absolute;
            top: 0;
            width: 100%;
            height: 560px;
            min-width: 1280px;
            background: #000;

            .swiper-wrapper {
                animation: billboard-motion 1s;
            }

            ul {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                min-width: 1600px;
                height: 560px;
                margin: 0 0 0 -50%;

                li {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    -webkit-transition: opacity 1s ease;
                    transition: opacity 1s ease;

                    img {
                        width: 100%;
                        height: auto;
                        min-height: 560px;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }

        .c_seller_intro_title_button {
            z-index: 2;
            position: relative;
            text-align: center;

            .text {
                font-size: 50px;
                line-height: 1.43;
                letter-spacing: -0.5px;
                color: #fff;
                font-family: "11StreetGothic";
            }

            .button_list {
                margin-top: 31px;

                li {
                    display: inline-block;
                    padding: 0 3px 0 4px;
                }
            }
        }
    }

    .c_seller_intro_guide_01 {
        min-width: 1280px;
        margin: 0 auto;
        padding: 90px 0 0 0;
        height: 667px;
        background: #f7f7f9;

        .type_list {
            overflow: hidden;
            width: 960px;
            padding-left: 1px;
            margin: 47px auto 0 auto;

            li {
                position: relative;
                float: left;
                width: 318px;
                // height: 377px;
                height: 424px;
                padding: 45px 0 0 0;
                margin: 0 0 0 -1px;
                text-align: center;
                border: 1px solid #e9e9e9;
                background: #fff;
                box-sizing: border-box;

                &:first-child:nth-last-child(2) {
                    // width: 50%;

                    &,
                    & ~ li {
                        width: 50%;
                    }
                }

                .icon {
                    display: block;
                    width: 96px;
                    height: 96px;
                    margin: 0 auto;

                    img {
                        width: 96px;
                        height: 96px;
                    }
                }

                .text {
                    margin-top: 17px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                }

                dl {
                    margin-top: 9px;

                    dt {
                        font-size: 26px;
                        font-weight: bold;
                        line-height: 1.31;
                        color: #333;
                    }

                    dd {
                        height: 67px;
                        margin-top: 13px;
                        font-size: 16px;
                        color: #616161;
                        line-height: 1.5;
                    }
                }

                .button_detail {
                    display: block;
                    width: 148px;
                    height: 41px;
                    margin: 0 auto;
                    font-size: 16px;
                    text-align: center;
                    line-height: 41px;
                    color: #111;
                    border: 1px solid #ccc;
                    border-radius: 30px;
                    background: #fff;

                    &:after {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        margin: -4px 0 0 4px;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_02);
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_02 {
        min-width: 1280px;
        margin: 0 auto;
        padding: 90px 0 0 0;
        height: 514px;
        background: #fff;

        .step_list {
            overflow: hidden;
            width: 959px;
            margin: 58px auto 0 auto;

            li {
                float: left;
                position: relative;
                width: 163px;
                padding: 0 36px 0 0;
                text-align: center;
                letter-spacing: 0;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 53px;
                    right: 0;
                    @include sprite-retina($sp_seller_intro_2x_ico_step_arrow);
                }

                &:last-child {
                    padding: 0;

                    &:before {
                        display: none;
                    }
                }

                dl {
                    &:before {
                        content: "";
                        display: block;
                        margin: 0 auto;
                        @include sprite-retina($sp_seller_intro_2x_ico_step_01);
                    }

                    dt {
                        span {
                            display: block;
                            margin-bottom: 3px;
                            font-size: 16px;
                            font-family: "roboto";
                        }

                        margin-top: 17px;
                        font-weight: bold;
                        font-size: 20px;
                        color: #333;
                    }

                    dd {
                        height: 74px;
                        margin-top: 16px;
                        font-size: 15px;
                        color: #666;
                        line-height: 1.37;

                        &.button_box {
                            height: auto;
                            margin-top: 0;
                        }
                    }
                }

                .button_detail {
                    display: block;
                    width: 110px;
                    height: 34px;
                    margin: 0 auto;
                    font-size: 14px;
                    text-align: center;
                    line-height: 34px;
                    color: #333;
                    border: 1px solid #ddd;
                    border-radius: 30px;
                    background: #fff;

                    &:after {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        margin: -4px 0 0 4px;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_03);
                    }
                }

                &.step_01 {
                    dl {
                        &:before {
                            @include sprite-retina($sp_seller_intro_2x_ico_step_01);
                        }
                    }
                }

                &.step_02 {
                    dl {
                        &:before {
                            @include sprite-retina($sp_seller_intro_2x_ico_step_02);
                        }
                    }
                }

                &.step_03 {
                    dl {
                        &:before {
                            @include sprite-retina($sp_seller_intro_2x_ico_step_03);
                        }
                    }
                }

                &.step_04 {
                    dl {
                        &:before {
                            @include sprite-retina($sp_seller_intro_2x_ico_step_04);
                        }
                    }
                }

                &.step_05 {
                    dl {
                        &:before {
                            @include sprite-retina($sp_seller_intro_2x_ico_step_05);
                        }
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_03 {
        min-width: 1280px;
        margin: 0 auto;
        padding: 90px 0 0 0;
        height: 667px;
        background: #f7f7f9;

        .guide_list_box {
            position: relative;
            width: 960px;
            padding: 0 62px 0 62px;
            margin: 52px auto 0 auto;

            .guide_list {
                overflow: hidden;
                width: 960px;

                ul {
                    @include clearfix;
                    position: relative;
                    width: 4000px;

                    li {
                        float: left;
                        width: 310px;
                        margin-right: 15px;
                        background: #fff;

                        &:last-child {
                            margin-right: 0;
                        }

                        .thumb_box {
                            width: 310px;

                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                            }
                        }

                        dl {
                            width: 268px;
                            height: 120px;
                            padding: 23px 20px 20px 20px;
                            border: 1px solid #efefef;
                            border-top: none;

                            dt {
                                font-size: 18px;
                                font-weight: bold;
                                color: #111;
                            }

                            dd {
                                margin-top: 12px;
                                font-size: 15px;
                                line-height: 1.5;
                                color: #666;
                            }
                        }

                        a {
                            &:hover {
                                text-decoration: none;

                                dd {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .navigator {
                position: absolute;
                top: 0;
                left: 50%;
                width: 1084px;
                margin: 0 0 0 -542px;

                button {
                    width: 1000px;
                    text-indent: -9999px;
                    position: absolute;
                    top: 157px;

                    &.btn_prev {
                        left: 0;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_prev);
                    }

                    &.btn_next {
                        right: 0;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_next);
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_04 {
        overflow: hidden;
        position: relative;
        min-width: 1280px;
        margin: 0 auto;
        padding: 90px 0 0 0;
        height: 590px;

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 100%;
            min-width: 1600px;
            height: 700px;
            margin: 0 0 0 -50%;
            background: url("/img/selleroffice/seller_intro/bg_tv.png") center top no-repeat;
            background-size: 100%;
        }

        .c_seller_intro_title {
            z-index: 2;
            position: relative;
            color: #fff;
        }

        .guide_list_box {
            z-index: 2;
            position: relative;
            width: 960px;
            padding: 0 62px 0 62px;
            margin: 52px auto 0 auto;

            .guide_list {
                overflow: hidden;
                width: 960px;

                ul {
                    @include clearfix;
                    position: relative;
                    width: 4000px;

                    li {
                        float: left;
                        width: 310px;
                        margin-right: 15px;
                        background: #fff;

                        &:last-child {
                            margin-right: 0;
                        }

                        .thumb_box {
                            position: relative;
                            width: 310px;

                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                            }

                            &:before {
                                content: "";
                                position: absolute;
                                bottom: 20px;
                                right: 20px;
                                @include sprite-retina($sp_seller_intro_2x_btn_play);
                            }
                        }

                        dl {
                            width: 268px;
                            height: 120px;
                            padding: 23px 20px 20px 20px;
                            border: 1px solid #efefef;
                            border-top: none;

                            dt {
                                font-size: 18px;
                                font-weight: bold;
                                color: #111;
                            }

                            dd {
                                margin-top: 12px;
                                font-size: 15px;
                                line-height: 1.5;
                                color: #666;
                                word-break: normal;
                            }
                        }

                        a {
                            &:hover {
                                text-decoration: none;

                                dd {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .navigator {
                position: absolute;
                top: 0;
                left: 50%;
                width: 1084px;
                margin: 0 0 0 -542px;

                button {
                    width: 1000px;
                    text-indent: -9999px;
                    position: absolute;
                    top: 157px;

                    &.btn_prev {
                        left: 0;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_prev);
                    }

                    &.btn_next {
                        right: 0;
                        @include sprite-retina($sp_seller_intro_2x_ico_arrow_next);
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_05 {
        min-width: 1280px;
        //height: 335px;
        margin: 0 auto;
        padding: 91px 0 91px 0;

        .c_billborad_seller_intro {
            position: relative;
            overflow: hidden;
            width: 960px;
            height: 245px;
            margin: 0 auto;
            background: #fff;

            .visual {
                overflow: hidden;
                width: 960px;
                height: 246px;
                margin: 0 auto;

                ul {
                    overflow: hidden;
                    width: 96000px;

                    li {
                        display: block;
                        float: left;

                        img {
                            width: 960px;
                            max-height: 245px;
                        }
                    }
                }
            }

            .navigator {
                position: absolute;
                top: 10px;
                right: 10px;
                bottom: auto;

                button {
                    float: left;
                    position: relative;
                    overflow: hidden;
                    line-height: 300px;

                    &.previous {
                        @include sprite-retina($sp_seller_intro_2x_btn_banner_prev);
                    }

                    &.next {
                        @include sprite-retina($sp_seller_intro_2x_btn_banner_next);
                    }
                }
            }

            .indicate {
                position: absolute;
                top: 12px;
                right: 61px;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: normal;
                box-sizing: border-box;

                .pagination {
                    .current {
                        font-weight: bold;
                    }
                }
            }

            &.resize {
                height: 325px;

                .visual {
                    height: 325px;

                    ul {
                        li {
                            img {
                                max-height: 325px;
                            }
                        }
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_06 {
        min-width: 1280px;
        margin: 0 auto;
        padding: 50px 0 0 0;
        height: 152px;
        background: #f7f7f9;

        ul {
            @include clearfix;
            width: 963px;
            margin: 0 auto;

            li {
                float: left;
                width: 319px;
                border-right: 1px solid #ebebeb;

                &:last-child {
                    border: none;
                }

                dl {
                    text-align: center;

                    dt {
                        font-size: 18px;
                        color: #333;
                    }

                    dd {
                        margin-top: 6px;

                        strong {
                            display: block;
                            margin-bottom: 8px;
                            font-size: 28px;
                            color: #333;
                            font-family: "11StreetGothic";
                        }

                        color: #666;
                        font-size: 16px;

                        .button_email {
                            display: block;
                            margin: 8px auto 16px auto;
                            text-indent: -9999px;
                            @include sprite-retina($sp_seller_intro_2x_ico_mail);
                        }

                        .button_ad {
                            display: block;
                            &:before {
                                content: "";
                                display: block;
                                margin: 17px auto 19px auto;
                                @include sprite-retina($sp_seller_intro_2x_ico_mail);
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }

        &.type_02 {
            ul {
                li {
                    width: 239px;

                    dl {
                        dd {
                            .button_email {
                                margin: 17px auto 19px auto;
                            }
                        }
                    }
                }
            }
        }

        &.type_03 {
            ul {
                li {
                    width: 226px;
                    &:first-child {
                        width: 280px;
                    }
                }
            }
        }
    }

    .c_seller_intro_guide_07 {
        overflow: hidden;
        position: relative;
        min-width: 1280px;
        margin: 0 auto;
        padding: 90px 0 0 0;
        height: 602px;

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 100%;
            min-width: 1600px;
            height: 692px;
            margin: 0 0 0 -50%;
            background: url("/img/selleroffice/seller_intro/bg_ad.png") center top no-repeat;
            background-size: 100%;
        }

        .c_seller_intro_title {
            z-index: 2;
            position: relative;
            color: #fff;
        }

        .guide_list {
            @include clearfix;
            z-index: 2;
            position: relative;
            width: 975px;
            margin: 52px auto 0 auto;

            li {
                float: left;
                width: 310px;
                height: 351px;
                margin: 0 7px 0 8px;
                background: #fff;
                .thumb_box {
                    width: 310px;
                    height: 174px;

                    img {
                        width: 310px;
                        height: 174px;
                    }
                }
                .text_button {
                    margin-top: 20px;
                    text-align: center;
                    .text {
                        font-size: 18px;
                        font-weight: bold;
                        color: #111;
                    }
                    .button_link {
                        display: inline-block;
                        border: 1px solid #ccc;
                        width: 170px;
                        height: 41px;
                        margin-top: 20px;
                        font-size: 16px;
                        text-align: center;
                        color: #111;
                        line-height: 43px;
                        border-radius: 22px;
                        background: #fff;
                        &:after {
                            content: "";
                            @include sprite-retina($sp_seller_intro_2x_ico_arrow);
                            display: inline-block;
                            margin-top: -3px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    .l_seller_intro_footer {
        min-width: 1280px;
        background: #eee;
    }

    .c_seller_intro_footer_menu {
        height: 54px;
        text-align: center;
        background: #ddd;

        li {
            display: inline-block;
            line-height: 54px;
            padding: 0 24px 0 23px;

            a {
                font-size: 13px;
                color: #333;
            }

            &:nth-child(4) {
                font-weight: bold;
            }
        }
    }

    .c_seller_intro_footer_info {
        .infomation_box {
            position: relative;
            width: 1005px;
            margin: 0 auto;
            padding: 35px 0 31px 275px;
            border-bottom: 1px solid #ddd;
            @include clearfix;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 67px;
                left: 160px;
                @include sprite-retina($sp_seller_intro_2x_footer_logo);
            }

            div {
                float: left;
                padding-right: 40px;

                &:last-child {
                    padding-right: 0;
                }

                dt {
                    font-size: 12px;
                    color: #666;
                }

                dd {
                    &.tel {
                        margin: 5px 0 10px 0;
                        font-size: 20px;
                        color: #666;
                        letter-spacing: -0.5px;
                    }

                    margin-top: 4px;
                    font-size: 12px;
                    color: #999;
                }
            }
        }

        .link_copyright_box {
            width: 1005px;
            margin: 0 auto;
            padding: 21px 0 31px 275px;

            .link_box {
                @include clearfix;

                li {
                    float: left;
                    margin-right: 6px;

                    a {
                        display: inline-block;
                        min-width: 106px;
                        height: 30px;
                        padding: 0 16px 0 16px;
                        font-size: 12px;
                        text-align: center;
                        color: #666;
                        line-height: 32px;
                        border: 1px solid #ddd;
                        background: #fff;
                    }
                }
            }

            .text {
                margin-top: 22px;
                font-size: 11px;
                font-weight: bold;
                color: #666;
            }

            .copyright {
                margin-top: 6px;
                font-size: 11px;
                color: #666;
            }
        }
    }
}

.c_modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &.active {
        display: block;
    }

    &.c_modal_dimmed {
        background: rgba(0, 0, 0, 0.2);
    }

    .modal_inner {
        position: relative;
        margin: 50vh auto;
        transform: translateY(-50%);
        background: #fff;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12);

        .modal_header {
            border-bottom: 1px solid #eee;

            .title {
                text-align: center;
            }
        }

        .modal_content {
            &.scroll {
                overflow-y: auto;
                height: 100px;
            }
        }

        .modal_footer {
            border-top: 1px solid #ddd;
            text-align: center;

            button {
                display: inline-block;
                margin: 0 5px;
            }
        }

        .modal_close {
            button {
                position: absolute;
                top: 17px;
                right: 21px;
                @include ico-cancel($size: 18px, $thick: 1px, $color: #111, $padding: 10px);
            }
        }
    }
}

.c_modal_seller_intro {
    z-index: 110;

    .modal_inner {
        width: 960px;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12);

        .modal_header {
            height: 70px;
            border-bottom: 1px solid #ddd;

            .title {
                font-size: 18px;
                line-height: 70px;
                color: #111;
            }
        }

        .modal_content {
            padding: 20px;

            &.scroll {
                height: 560px;
                padding: 20px 39px 39px 39px;
                max-height: 560px;
            }

            .c_tab_content {
                display: none;

                &.active {
                    display: block;
                }

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #111;
                }

                .info_01 {
                    margin-top: 25px;

                    .process_box {
                        @include clearfix;
                        height: 138px;
                        margin-top: 9px;
                        padding: 22px 0 0 0;
                        text-align: center;
                        border: 1px solid #eee;
                        background: #fafafa;

                        li {
                            position: relative;
                            display: inline-block;
                            width: 133px;
                            padding-right: 36px;
                            font-size: 14px;
                            font-weight: bold;
                            text-align: center;
                            color: #333;

                            &:last-child {
                                padding-right: 0;

                                &:after {
                                    display: none;
                                }
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                top: 30px;
                                right: 0;
                                @include sprite-retina($sp_seller_intro_2x_ico_dot);
                            }

                            &:before {
                                content: "";
                                display: block;
                                margin: 0 auto 3px auto;
                                @include sprite-retina($sp_seller_intro_2x_ico_join_01);
                            }

                            &.type_01 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_01);
                                }
                            }

                            &.type_02 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_02);
                                }
                            }

                            &.type_03 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_03);
                                }
                            }

                            &.type_04 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_04);
                                }
                            }

                            &.type_05 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_05);
                                }
                            }

                            &.type_06 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_06);
                                }
                            }

                            &.type_07 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_07);
                                }
                            }

                            &.type_08 {
                                &:before {
                                    @include sprite-retina($sp_seller_intro_2x_ico_join_08);
                                }
                            }
                        }
                    }
                }

                .info_02 {
                    padding-top: 41px;

                    ul {
                        margin-top: 10px;

                        li {
                            position: relative;
                            padding-left: 13px;
                            font-size: 15px;
                            color: #111;
                            line-height: 1.67;

                            &:before {
                                content: "";
                                position: absolute;
                                top: 10px;
                                left: 3px;
                                display: block;
                                width: 3px;
                                height: 3px;
                                background: #494949;
                            }
                        }
                    }
                }

                .info_03 {
                    padding-top: 39px;

                    .text_list_box {
                        margin-top: 12px;
                        font-size: 14px;
                        color: #666;

                        li {
                            margin-bottom: 2px;

                            a {
                                color: #0b83e6;
                            }
                        }
                    }
                }
            }
        }

        .modal_footer {
            padding: 10px 0;
            text-align: center;

            button {
                min-width: 178px;
                height: 48px;
                line-height: 48px;
                padding: 0 30px;
                font-weight: bold;
                font-size: 15px;
                border: 1px solid #999;
                background: #fff;

                &.confirm {
                    border: none;
                    background: $color-11st-red;
                    color: #fff;
                }
            }
        }
    }
}

.c_table_seller_intro {
    margin-top: 11px;
    border-top: 1px solid #111;

    table {
        width: 100%;
        table-layout: fixed;
        text-align: center;
        border: 1px solid #ebebeb;
        border-top: none;
        background: #fff;

        th {
            height: 46px;
            font-size: 15px;
            color: #111;
            border-right: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;

            &:last-child {
                border-right: none;
            }
        }

        td {
            padding: 20px;
            font-size: 15px;
            color: #111;
            border-right: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;
            text-align: left;

            &:last-child {
                border-right: none;
            }

            ul {
                li {
                    line-height: 1.67;
                }
            }

            &.top {
                vertical-align: top;
            }
        }
    }
}

.c_tab_seller_intro {
    ul {
        @include clearfix;

        li {
            float: left;
            width: calc(100% / 3);

            &:first-child {
                > button {
                    &::before {
                        left: 0;
                    }
                }
            }

            > button {
                position: relative;
                display: block;
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                line-height: 60px;
                color: #111;
                font-weight: bold;
                box-sizing: border-box;
                text-align: center;
                background-color: #fff;

                &::before {
                    position: absolute;
                    left: -1px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    border: 1px solid #ddd;
                    content: "";
                }

                &[aria-selected="true"] {
                    background-color: $color-11st-red;
                    color: #fff;

                    &:before {
                        right: -1px;
                        border-color: $color-11st-red;
                    }

                    &:hover {
                        color: #fff;
                    }
                }

                &:hover {
                    color: $color-11st-red;
                }
            }
        }
    }

    .tab_inner {
        overflow: hidden;
    }
}
